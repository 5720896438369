import {
  InstanceOverviewLayout,
  InstanceProvider,
} from '@internal/plugin-qm-config';
import React, { useContext } from 'react';
import { SubscriptionContext } from './SubscriptionProvider';

export const SubscriptionConfig = () => {
  const { env, tenantId } = useContext(SubscriptionContext);

  return (
    <InstanceProvider
      env={env}
      serviceKey="server-tenants"
      instanceKey={tenantId}
    >
      <InstanceOverviewLayout />
    </InstanceProvider>
  );
};

import { createApp } from '@backstage/app-defaults';
import { AppRouter, FeatureFlagged, FlatRoutes } from '@backstage/core-app-api';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { googleAuthApiRef } from '@backstage/core-plugin-api';
import { ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import { subscriptionColumnsFunc } from '@internal/plugin-qm-ephemeral-subscriptions';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ExplorePage } from '@backstage-community/plugin-explore';
import { GraphiQLPage } from '@backstage-community/plugin-graphiql';
import { HomepageCompositionRoot, VisitListener } from '@backstage/plugin-home';
import { orgPlugin } from '@backstage/plugin-org';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  TechDocsIndexPage,
  TechDocsReaderPage,
  techdocsPlugin,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { UnifiedThemeProvider } from '@backstage/theme';
import { DxpSearchPage } from '@internal/plugin-dxp-search';
import { AnnouncementsPage } from '@internal/plugin-qm-announcements';
import { IdentityComponent } from '@internal/plugin-qm-api';
import { QmBloodhoundPage } from '@internal/plugin-qm-bloodhound';
import {
  QmConfigPage,
  QmCreateServicePage,
  QmInstancePage,
  QmServicePage,
} from '@internal/plugin-qm-config';
import {
  QmEphemeralSubscriptionDrilldownPage,
  QmEphemeralSubscriptionsPage,
} from '@internal/plugin-qm-ephemeral-subscriptions';
import {
  QmFeatureFlagDetailPage,
  QmFeatureFlagListPage,
} from '@internal/plugin-qm-feature-flags';
import { QmOxPage, QmOxSummaryPage } from '@internal/plugin-qm-ox';
import { ProductFlagsPage } from '@internal/plugin-qm-product-flags';
import { QmExcellencePage } from '@internal/plugin-qm-excellence';
import { QmTestMetricDetailPage } from '@internal/plugin-qm-test-metrics';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import LightIcon from '@material-ui/icons/WbSunny';

import React from 'react';
import { Route } from 'react-router-dom';
import { apis } from './apis';
import { Root } from './components/Root';
import { entityPage } from './components/catalog/EntityPage';
import { HomePage } from './components/home/HomePage';
import { searchPage } from './components/search/SearchPage';
import {
  quantumLight,
  quantumFabriq,
  quantumDark,
  quantumCyberpunk,
  quantumMatrix,
  solarizedDarkTheme,
  quantumCyberpunkLight,
} from './themes';

const app = createApp({
  featureFlags: [
    {
      pluginId: 'qm-announcements-create',
      name: 'qm-announcements-create',
      description: 'Enable QM Announcements Creation',
    },
    {
      pluginId: 'qm-tech-insights',
      name: 'tech-insights',
      description: 'Display the Tech Insights Plugin',
    },
  ],
  themes: [
    {
      id: 'quantum-light',
      title: 'Light',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={quantumLight} children={children} />
      ),
    },
    {
      id: 'quantum-dark',
      title: 'Dark',
      variant: 'dark',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={quantumDark} children={children} />
      ),
    },
    {
      id: 'quantum-fabriq',
      title: 'Fabriq',
      variant: 'dark',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={quantumFabriq} children={children} />
      ),
    },
    {
      id: 'quantum-matrix',
      title: 'Matrix',
      variant: 'dark',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={quantumMatrix} children={children} />
      ),
    },
    {
      id: 'solarized-dark',
      title: 'Solarized',
      variant: 'dark',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={solarizedDarkTheme} children={children} />
      ),
    },
    {
      id: 'quantum-cyberpunk',
      title: 'Cyberpunk',
      variant: 'dark',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={quantumCyberpunk} children={children} />
      ),
    },
    {
      id: 'quantum-cyberpunk-light',
      title: 'Cyberpunk Light',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider
          theme={quantumCyberpunkLight}
          children={children}
        />
      ),
    },
  ],
  apis,
  components: {
    SignInPage: props => {
      return (
        <SignInPage
          {...props}
          auto
          provider={{
            id: 'google-auth-provider',
            title: 'Google',
            message: 'Sign In using Google',
            apiRef: googleAuthApiRef,
          }}
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    // bind(apiDocsPlugin.externalRoutes, {
    //   createComponent: scaffolderPlugin.routes.root,
    // });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    // bind(catalogGraphPlugin.externalRoutes, {
    //   catalogEntity: catalogPlugin.routes.catalogEntity,
    // });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route
      path="/catalog"
      element={
        <CatalogIndexPage
          initiallySelectedFilter="all"
          columns={subscriptionColumnsFunc}
        />
      }
    />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/explore" element={<ExplorePage />} />
    <Route path="/docs" element={<TechDocsIndexPage initialFilter="all" />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <Mermaid />
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/catalog-import" element={<CatalogImportPage />} />
    <Route
      id="catalog-graph"
      path="/catalog-graph"
      element={
        <CatalogGraphPage
          initialState={{
            selectedKinds: [
              'component',
              'domain',
              'system',
              'api',
              'group',
              'subscription',
            ],
          }}
        />
      }
    />

    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/qm-ox-scores" element={<QmOxSummaryPage />} />
    <Route path="/qm-ox" element={<QmOxPage />} />
    <FeatureFlagged with="tech-insights">
      <Route path="/operational-excellence" element={<QmExcellencePage />} />
    </FeatureFlagged>
    <Route path="/qm-bloodhound" element={<QmBloodhoundPage />} />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route
      path="/qm-subscription-manager"
      element={<QmEphemeralSubscriptionsPage />}
    />
    <Route
      path="/qm-subscription-manager/:name/detail"
      element={<QmEphemeralSubscriptionDrilldownPage />}
    />
    <Route path="/graphiql" element={<GraphiQLPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1400} height={800} />}
    />
    <Route path="/qm-feature-flags" element={<QmFeatureFlagListPage />} />
    <Route
      path="/qm-feature-flags/:flag/detail"
      element={<QmFeatureFlagDetailPage />}
    />
    <Route
      path="/qm-ephemeral-subscriptions"
      element={<QmEphemeralSubscriptionsPage />}
    />
    <Route
      path="/qm-ephemeral-subscriptions/:name/detail"
      element={<QmEphemeralSubscriptionDrilldownPage />}
    />

    <Route path="/qm-config" element={<QmConfigPage />} />
    <Route
      path="/qm-config/services/:environment/:service"
      element={<QmServicePage />}
    />
    <Route
      path="/qm-config/services/create"
      element={<QmCreateServicePage />}
    />
    <Route
      path="/qm-config/services/:environment/:service/instances/:instance"
      element={<QmInstancePage />}
    />
    <Route
      path="/qm-test-metrics/:namespace/test/:testname"
      element={<QmTestMetricDetailPage />}
    />
    <Route path="/dxp-search" element={<DxpSearchPage />} />
    <Route path="/product-permissions/:env" element={<ProductFlagsPage />} />
    <Route path="/qm-excellence" element={<QmExcellencePage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <VisitListener />
      <Root>{routes}</Root>
    </AppRouter>
    <IdentityComponent />
  </>,
);

import React from 'react';
import { EntityLinkComponent } from '../EntityLinkComponent';

export const UserByEmail = ({ email }: { email: string }) => {
  const ownerRef = email.split('@')[0];

  return (
    <EntityLinkComponent
      entityRef={{
        name: ownerRef,
        kind: 'user',
        namespace: 'default',
      }}
    />
  );
};

export const Owner = ({ email, type }: { email: string; type: string }) => {
  if (type !== 'ephemeral') {
    // Permanent subs do not have owners
    return <></>;
  }
  return <UserByEmail email={email} />;
};

import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAsync from 'react-use/lib/useAsync';

import type { Subscription } from '../../types';

export const SubscriptionContext = createContext<any>(null);

type Filter = {
  types: Array<string>;
  hosts: Array<string>;
  statuses: Array<string>;
  owners: Array<string>;
};

type ProviderProps = {
  children: any;
};

const SubscriptionProvider = ({ children }: ProviderProps) => {
  const [refreshes, setRefresh] = useState<number>(0);
  const navigate = useNavigate();

  const refresh = () => setRefresh(refreshes + 1);

  const [filter, setFilter] = useState<Filter>(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return {
      types: searchParams.getAll('types'),
      statuses: searchParams.getAll('statuses'),
      hosts: searchParams.getAll('hosts'),
      owners: searchParams.getAll('owners'),
    };
  });

  const [hosts, setHosts] = useState<Array<string>>([]);
  const [statuses, setStatuses] = useState<Array<string>>([]);
  const [types, setTypes] = useState<Array<string>>([]);
  const [owners, setOwners] = useState<Array<string>>([]);
  const [subscriptions, setSubscriptions] = useState<Array<Subscription>>([]);
  const [filtered, setFiltered] = useState<Array<Subscription>>([]);
  const [search, setSearch] = useState<string | null>(
    new URLSearchParams(window.location.search).get('search'),
  );

  useEffect(() => {
    const hostFilter = filter.hosts.length === 0 ? hosts : filter.hosts;
    const ownerFilter = filter.owners.length === 0 ? owners : filter.owners;
    const typesFilter = filter.types.length === 0 ? types : filter.types;
    const statusFilter =
      filter.statuses.length === 0 ? statuses : filter.statuses;

    const filterSearch = (val: string): boolean => {
      return search ? val.includes(search) : true;
    };

    const results = subscriptions.filter(
      s =>
        hostFilter.includes(s.host) &&
        ownerFilter.includes(s.owner) &&
        typesFilter.includes(s.type) &&
        statusFilter.includes(s.status) &&
        (filterSearch(s.name) || filterSearch(s.host) || filterSearch(s.owner)),
    );

    const params = new URLSearchParams();
    filter.hosts.forEach(host => params.append('hosts', host));
    filter.types.forEach(type => params.append('types', type));
    filter.owners.forEach(owner => params.append('owners', owner));
    filter.statuses.forEach(status => params.append('statuses', status));
    if (search) params.set('search', search);

    navigate(`/qm-subscription-manager?${params.toString()}`, {
      replace: true,
    });

    setFiltered(results);
  }, [filter, hosts, owners, statuses, subscriptions, types, search, navigate]);

  const { fetch } = useApi(fetchApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const { loading } = useAsync(async (): Promise<Subscription[]> => {
    const subscriptionManagerUrl = `${await discoveryApi.getBaseUrl(
      'proxy',
    )}/subscription-manager`;
    const response = await fetch(
      `${subscriptionManagerUrl}/api/v1/subscriptions`,
    );
    const data = await response.json();
    try {
      const hostResults = Array.from<string>(
        new Set(data.map((d: any) => d.host)),
      );
      const ownerResults = Array.from<string>(
        new Set(data.map((d: any) => d.owner)),
      );
      const typeResults = Array.from<string>(
        new Set(data.map((d: any) => d.type)),
      );
      const statusResults = Array.from<string>(
        new Set(data.map((d: any) => d.status)),
      );

      ownerResults.sort();
      hostResults.sort();
      typeResults.sort();
      statusResults.sort();

      setHosts(hostResults);
      setOwners(ownerResults);
      setTypes(typeResults);
      setStatuses(statusResults);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('error', err);
    }
    setSubscriptions(data);
    return data;
    // TODO handle error
  }, [refreshes]);

  if (!filtered || (loading && subscriptions.length === 0)) return null;

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptions: filtered,
        owners,
        statuses,
        types,
        hosts,
        setFilter,
        search,
        setSearch,
        filter,
        refresh,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionProvider;

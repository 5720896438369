import { InfoCard } from '@backstage/core-components';
import { usePermission } from '@backstage/plugin-permission-react';
import { PERMISSIONS } from '@internal/plugin-qm-ephemeral-subscriptions-common';
import {
  Chip,
  Grid,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopy from '@material-ui/icons/FileCopy';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Launch from '@material-ui/icons/Launch';
import MoreVertRounded from '@material-ui/icons/MoreVertRounded';
import Policy from '@material-ui/icons/Policy';
import React, { useContext } from 'react';
import type { Service } from '../../types';
import { DeleteSubscriptionComponent } from '../SubscriptionActionComponents/MarkSubForDeletionDialog';
import { TagServiceDialog } from '../SubscriptionActionComponents/TagServiceDialog';
import { UpdateSubscriptionDataSourceComponent } from '../SubscriptionActionComponents/UpdateSubscriptionDataSourceComponent';
import { UpdateSubscriptionExpirationComponent } from '../SubscriptionActionComponents/UpdateSubscriptionExpirationComponent';
import { DataSource } from '../SubscriptionInfo/DataSource';
import { DockerImage } from '../SubscriptionInfo/DockerImage';
import { Host } from '../SubscriptionInfo/Host';
import { Expiration, NiceDate } from '../SubscriptionInfo/NiceDate';
import { Service as ServiceComponent } from '../SubscriptionInfo/Service';
import { Status } from '../SubscriptionInfo/Status';
import { Owner } from '../SubscriptionInfo/UserByEmail';
import { SubscriptionPoliciesDialog } from '../SubscriptionListComponent/UpdatePolicies';
import { SubscriptionContext } from './SubscriptionProvider';

const usePolicyStyles = makeStyles({
  tooltip: {
    marginLeft: '4px',
    verticalAlign: 'middle',
  },
  text: {
    fontSize: '11pt',
  },
});

const DetailGrid = ({
  data,
}: {
  data: ({ name: string; value: JSX.Element | null } | false)[];
}) => {
  return (
    <>
      {data.map(
        val =>
          val === false || (
            <Grid key={val.name} item xs={12} sm={6} md={4}>
              <Typography variant="caption" color="textSecondary">
                {val.name}
              </Typography>
              <Typography>{val.value}</Typography>
            </Grid>
          ),
      )}
    </>
  );
};

export const SubscriptionDetail = () => {
  const subscription = useContext(SubscriptionContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showDataSourceEdit, setShowDataSourceEdit] =
    React.useState<boolean>(false);
  const [expirationDialogOpen, setExpirationDialogOpen] =
    React.useState<boolean>(false);
  const [showTagServiceDialog, setShowTagServiceDialog] =
    React.useState<boolean>(false);
  const [policyDialogOpen, setPolicyDialogOpen] =
    React.useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] =
    React.useState<boolean>(false);
  const [editedService, setEditedService] = React.useState<Service>(
    {} as Service,
  );

  const handleEditImageClick = (service: Service) => {
    setEditedService(service);
    setShowTagServiceDialog(true);
  };

  const VersionTable = ({ services }: { services?: Service[] }) => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Replicas</TableCell>
            <TableCell>Host</TableCell>
            <TableCell>Tag</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {services?.map(service => (
            <TableRow key={service.name}>
              <TableCell>
                <ServiceComponent name={service.name} />
              </TableCell>
              <TableCell>{service.replicas}</TableCell>
              <TableCell>
                <Host label={service.host} />
              </TableCell>
              <TableCell>
                <DockerImage image={service.image} />
              </TableCell>
              <TableCell>
                <IconButton
                  aria-label="Edit"
                  title="Edit Image"
                  onClick={() => {
                    handleEditImageClick(service);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const handleActionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const infoCardAction = (
    <IconButton
      onClick={handleActionClick}
      aria-label="more"
      id="long-button"
      aria-haspopup="true"
    >
      <MoreVertRounded />
    </IconButton>
  );

  const { loading: loadingPermission, allowed } = usePermission({
    permission: PERMISSIONS.UPDATE_WITH_DATA_SOURCE,
    resourceRef: subscription.name,
  });

  const InfoTooltip = (props: { title: React.ReactElement }) => {
    const classes = usePolicyStyles();
    const { title } = props;
    return (
      <Tooltip
        title={title}
        className={classes.tooltip}
        classes={{ tooltip: classes.text }}
        placement="right"
      >
        <InfoIcon fontSize="small" />
      </Tooltip>
    );
  };

  const {
    loading: loadingUpdatePolicyPermission,
    allowed: allowedUpdatePolicy,
  } = usePermission({
    permission: PERMISSIONS.UPDATE_POLICY,
    resourceRef: subscription.name,
  });

  const ActionMenu = () => {
    const grafanaEnv = subscription.type === 'ephemeral' ? 'dev' : 'prod';
    const logsUrl = `https://grafana.${grafanaEnv}.qmengineering.com/explore?orgId=1&left=%7B%22datasource%22:%22loki%22,%22queries%22:%5B%7B%22refId%22:%22A%22,%22datasource%22:%7B%22type%22:%22loki%22,%22uid%22:%22loki%22%7D,%22editorMode%22:%22code%22,%22expr%22:%22%7Bsub%3D%5C%22${subscription.name}%5C%22%7D%22,%22queryType%22:%22range%22%7D%5D,%22range%22:%7B%22from%22:%22now-1h%22,%22to%22:%22now%22%7D%7D`;

    return (
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={handleActionClose}>
        <MenuItem
          data-event-id="sub-logs-button"
          key="Logs"
          onClick={() => window.open(logsUrl)}
        >
          <ListItemIcon>
            <Launch />
          </ListItemIcon>
          <ListItemText>Logs</ListItemText>
        </MenuItem>
        {!loadingUpdatePolicyPermission && allowedUpdatePolicy && (
          <MenuItem
            data-event-id="change-policies"
            key="Update Policies"
            onClick={() => {
              setPolicyDialogOpen(true);
            }}
          >
            <ListItemIcon>
              <Policy />
            </ListItemIcon>
            <ListItemText>Update Policies</ListItemText>
          </MenuItem>
        )}
        {!loadingPermission && allowed && subscription.type === 'ephemeral' && (
          <>
            <MenuItem
              data-event-id="update-data-source-button"
              key="UpdateDataSource"
              onClick={() => {
                setShowDataSourceEdit(true);
                handleActionClose(); // close menu options when you click an option
              }}
            >
              <ListItemIcon>
                <FileCopy />
              </ListItemIcon>
              <ListItemText>Update Data Source</ListItemText>
            </MenuItem>
            <MenuItem
              data-event-id="update-expiration-button"
              key="UpdateExpiration"
              onClick={() => {
                setExpirationDialogOpen(true);
              }}
            >
              <ListItemIcon>
                <HourglassEmptyIcon />
              </ListItemIcon>
              <ListItemText>Set Expiration</ListItemText>
            </MenuItem>
            <MenuItem
              data-event-id="mark-for-deletion"
              key="MarkForDeletion"
              onClick={() => setDeleteDialogOpen(true)}
            >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>Mark Sub For Deletion</ListItemText>
            </MenuItem>
          </>
        )}
      </Menu>
    );
  };

  const UpdateDataSource = () => {
    if (!showDataSourceEdit) return <></>;

    return (
      <UpdateSubscriptionDataSourceComponent
        subscription={subscription.name}
        currentDataSource={subscription.data_source}
        handleCloseDialog={() => {
          setShowDataSourceEdit(false);
        }}
      />
    );
  };

  const lastUpdatedTime = subscription.services
    ? subscription.services[0].last_updated
    : '';

  return (
    <Grid container>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InfoCard title="Details" action={infoCardAction}>
            <Grid container spacing={2}>
              <Grid container item>
                <Grid item xs={12}>
                  <Typography variant="caption" color="textSecondary">
                    Subdomain
                  </Typography>
                  <Typography>
                    <Link
                      href={`https://${subscription.name}.quantummetric.com`}
                    >
                      {subscription.name}.quantummetric.com
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" color="textSecondary">
                    Tenant ID
                  </Typography>
                  <Typography>
                    <code>{subscription.tenantId}</code>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item>
                <DetailGrid
                  data={[
                    {
                      name: 'Type',
                      value: (
                        <Chip variant="outlined" label={subscription.type} />
                      ),
                    },
                    subscription.type === 'ephemeral' && {
                      name: 'Owner',
                      value: (
                        <Owner
                          email={subscription.owner}
                          type={subscription.type}
                        />
                      ),
                    },
                    {
                      name: 'Host',
                      value: <Host label={subscription.host} />,
                    },
                    subscription.type === 'ephemeral' && {
                      name: 'Status',
                      value: (
                        <Status
                          operations={subscription.operations}
                          status={subscription.status}
                          type={subscription.type}
                        />
                      ),
                    },
                    subscription.type === 'ephemeral' && {
                      name: 'Expiration',
                      value: (
                        <Expiration
                          date={subscription.expiration}
                          type={subscription.type}
                        />
                      ),
                    },
                    subscription.type === 'ephemeral' && {
                      name: 'Data Source',
                      value: (
                        <DataSource
                          label={subscription.data_source}
                          type={subscription.type}
                        />
                      ),
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </InfoCard>
        </Grid>
        <Grid item xs={6}>
          <InfoCard
            title={
              <>
                Services
                <InfoTooltip
                  title={
                    <>
                      Last Updated: <NiceDate date={lastUpdatedTime} />
                    </>
                  }
                />
              </>
            }
          >
            <VersionTable services={subscription.services} />
          </InfoCard>
        </Grid>
      </Grid>
      <TagServiceDialog
        subName={subscription.name}
        dialogOpen={showTagServiceDialog}
        service={editedService}
        handleDialogClose={() => {
          setEditedService({} as Service);
          setShowTagServiceDialog(false);
          subscription.refresh();
        }}
      />
      <ActionMenu />
      <UpdateDataSource />
      <UpdateSubscriptionExpirationComponent
        dialogOpen={expirationDialogOpen}
        subscription={subscription.name}
        expiration={subscription.expiration}
        handleCloseDialog={() => {
          setExpirationDialogOpen(false);
          subscription.refresh();
        }}
      />
      <SubscriptionPoliciesDialog
        dialogOpen={policyDialogOpen}
        handleCloseDialog={() => {
          setPolicyDialogOpen(false);
          subscription.refresh();
        }}
        selectedRow={{ sub: subscription.name, type: subscription.type }}
      />
      <DeleteSubscriptionComponent
        dialogOpen={deleteDialogOpen}
        subscription={subscription.name}
        handleCloseDialog={() => {
          setDeleteDialogOpen(false);
          subscription.refresh();
        }}
      />
    </Grid>
  );
};

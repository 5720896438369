import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  Button,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { useState } from 'react';

type ComponentProps = {
  dialogOpen: boolean;
  handleCloseDialog: () => void;
  subscription: string | null;
  expiration: string;
};

const expirationOptions = {
  '1Day': '1 Day',
  '1Week': '1 Week',
  '2Weeks': '2 Weeks',
  '1Month': '1 Month',
};

export const UpdateSubscriptionExpirationComponent = ({
  dialogOpen,
  handleCloseDialog,
  subscription,
  expiration,
}: ComponentProps) => {
  const { fetch } = useApi(fetchApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const [submitting, setSubmitting] = useState<boolean>();
  const [newExpiration, setNewExpiration] = useState<string | undefined>(
    undefined,
  );
  const [confirmText, setConfirmText] = useState<string | undefined>(undefined);

  const close = () => {
    setConfirmText(undefined);
    handleCloseDialog();
  };

  const handleSubmit = () => {
    const now = new Date();
    const expirationDate = new Date(now);

    switch (newExpiration) {
      case '1Day':
        expirationDate.setDate(now.getDate() + 1);
        break;
      case '1Week':
        expirationDate.setDate(now.getDate() + 7);
        break;
      case '2Weeks':
        expirationDate.setDate(now.getDate() + 14);
        break;
      case '1Month':
        expirationDate.setMonth(now.getMonth() + 1);
        break;
      default:
        break;
    }

    const formattedExpiration = expirationDate.toISOString();
    const formattedNewExpirationString = `${formattedExpiration.slice(0, 19)}Z`;
    if (submitting) {
      return;
    }
    setSubmitting(true);
    (async () => {
      const subscriptionManagerUrl = `${await discoveryApi.getBaseUrl(
        'proxy',
      )}/subscription-manager`;
      await fetch(
        `${subscriptionManagerUrl}/api/v1/subscriptions/${subscription}`,
        {
          method: 'PUT',
          body: JSON.stringify({ expiration: formattedNewExpirationString }),
        },
      );
      setSubmitting(false);
      close();
    })();
  };

  const validInput = (): [boolean, string] => {
    if (
      confirmText === undefined ||
      confirmText.length === 0 ||
      confirmText !== subscription
    ) {
      return [false, ''];
    }

    return [true, ''];
  };

  const [valid] = validInput();

  const handleExpirationChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const { value } = event.target as HTMLSelectElement;
    setNewExpiration(value);
  };

  const formattedExpiration = new Date(
    parseInt(expiration, 10),
  ).toLocaleDateString();

  const handleConfirmChange = ({
    target: { value },
  }: {
    target: { value: string };
  }) => {
    setConfirmText(value);
  };

  return (
    <Dialog open={dialogOpen} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle>Update Subscription Expiration</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid item xs={12} style={{ marginBottom: '10px' }}>
            <Typography>
              Update the expiration for the subscription{' '}
              <strong>{subscription}</strong>.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Current Expiration"
              variant="outlined"
              defaultValue={formattedExpiration}
              fullWidth
              margin="normal"
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="new-expiration-date-label">
                New Expiration
              </InputLabel>
              <Select
                labelId="new-expiration-date-label"
                label="New Expiration"
                name="expiration"
                onChange={handleExpirationChange}
              >
                {Object.entries(expirationOptions).map(([key, label]) => (
                  <MenuItem key={key} value={key}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              fullWidth
              variant="outlined"
              label="Enter the subscription name to confirm"
              onChange={handleConfirmChange}
              helperText={subscription}
            />
          </Grid>
          <DialogActions>
            <Button onClick={close} color="default">
              Close
            </Button>
            <Button disabled={!valid} onClick={handleSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

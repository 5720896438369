import { InfoCard } from '@backstage/core-components';
import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import React, { useContext, useState } from 'react';
import { useAsync } from 'react-use';
import { DockerImage } from '../SubscriptionInfo/DockerImage';
import { NiceDate } from '../SubscriptionInfo/NiceDate';
import { Service } from '../SubscriptionInfo/Service';
import { UserByEmail } from '../SubscriptionInfo/UserByEmail';
import { SubscriptionContext } from './SubscriptionProvider';

type auditEvent = {
  id: string;
  subscription_name: string;
  service_name: string;
  image: string;
  user: string;
  note: string;
  timestamp: string;
};

const usePolicyStyles = makeStyles({
  tooltip: {
    marginLeft: '4px',
    verticalAlign: 'text-bottom',
  },
  text: {
    fontSize: '11pt',
  },
});

const InfoTooltip = (props: { title: string }) => {
  const classes = usePolicyStyles();
  const { title } = props;
  return (
    <Tooltip
      title={title}
      className={classes.tooltip}
      classes={{ tooltip: classes.text }}
      placement="right"
    >
      <InfoIcon fontSize="small" />
    </Tooltip>
  );
};

export const SubscriptionVersions = () => {
  const subscription = useContext(SubscriptionContext);
  const { fetch } = useApi(fetchApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const [auditData, setAuditData] = useState<auditEvent[]>([{} as auditEvent]);

  const { loading } = useAsync(async () => {
    const subscriptionManagerUrl = `${await discoveryApi.getBaseUrl(
      'proxy',
    )}/subscription-manager`;
    const response = await fetch(
      `${subscriptionManagerUrl}/api/v1/service/audit?subscription=${subscription.name}`,
    );
    const data = await response.json();

    // order data with newest first
    data.sort((a: auditEvent, b: auditEvent) => {
      const aDate = Date.parse(a.timestamp);
      const bDate = Date.parse(b.timestamp);
      return bDate - aDate;
    });

    setAuditData(data);
  }, [subscription.name]);

  const VersionTable = ({ auditEvents }: { auditEvents?: auditEvent[] }) => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Services</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Tag</TableCell>
            <TableCell>Timestamp</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            auditEvents?.map(auditEvent => (
              <TableRow key={auditEvent.service_name}>
                <TableCell>
                  <Service name={auditEvent.service_name} />
                </TableCell>
                <TableCell>
                  <UserByEmail email={auditEvent.user} />
                </TableCell>
                <TableCell>
                  <DockerImage image={auditEvent.image} />
                </TableCell>
                <TableCell>
                  <NiceDate date={auditEvent.timestamp} />
                </TableCell>
                <TableCell>
                  {auditEvent.note ? 'Tag' : 'Release'}{' '}
                  <InfoTooltip title={auditEvent.note} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <InfoCard variant="gridItem" title="Service Version Updates">
          <VersionTable auditEvents={auditData} />
        </InfoCard>
      </Grid>
    </Grid>
  );
};

import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { createContext, useContext, useState } from 'react';
import { SubscriptionContext } from '../SubscriptionListComponent/SubscriptionContext';

type DeleteSubscriptionContext = {
  showDialog: (subscription: string) => void;
  hideDialog: () => void;
};

const initialState = {
  showDialog: () => {},
  hideDialog: () => {},
};

export const DeleteSubscriptionContextContext =
  createContext<DeleteSubscriptionContext>(initialState);

type ComponentProps = {
  dialogOpen: boolean;
  subscription: string | null;
  handleCloseDialog: () => void;
};

export const DeleteSubscriptionComponent = ({
  dialogOpen,
  subscription,
  handleCloseDialog,
}: ComponentProps) => {
  const { fetch } = useApi(fetchApiRef);
  const discoveryApi = useApi(discoveryApiRef);

  const [submitting, setSubmitting] = useState<boolean>();
  const [confirmText, setConfirmText] = useState<string | undefined>(undefined);
  const subscriptionContext = useContext(SubscriptionContext);

  const close = () => {
    setConfirmText(undefined);
    handleCloseDialog();
  };

  const handleSubmit = () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    (async () => {
      const subscriptionManagerUrl = `${await discoveryApi.getBaseUrl(
        'proxy',
      )}/subscription-manager`;
      await fetch(
        `${subscriptionManagerUrl}/api/v1/subscriptions/${subscription}`,
        {
          method: 'PUT',
          body: JSON.stringify({ status: 'marked_for_deletion' }),
        },
      );
      handleCloseDialog();
      setSubmitting(false);
      if (subscriptionContext.refresh) {
        subscriptionContext.refresh();
      }
    })();
  };

  const validInput = (): [boolean, string] => {
    if (
      confirmText === undefined ||
      confirmText.length === 0 ||
      confirmText !== subscription
    ) {
      return [false, ''];
    }

    return [true, ''];
  };

  const [valid] = validInput();

  const handleConfirmChange = ({
    target: { value },
  }: {
    target: { value: string };
  }) => {
    setConfirmText(value);
  };

  return (
    <Dialog open={dialogOpen} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle>Mark Sub For Deletion Subscription</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid item xs={12} style={{ marginBottom: '10px' }}>
            <Typography>
              Mark Subscription For Deletion <strong>{subscription}</strong>.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                variant="outlined"
                label="Enter the subscription name to confirm"
                onChange={handleConfirmChange}
                helperText={subscription}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={close} color="default">
              Close
            </Button>
            <Button disabled={!valid} onClick={handleSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

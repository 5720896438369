import {
  Header,
  HeaderLabel,
  Page,
  TabbedLayout,
} from '@backstage/core-components';
import React from 'react';
import { useParams } from 'react-router-dom';
import { SubscriptionConfig } from './SubscriptionConfig';
import { SubscriptionDetail } from './SubscriptionDetail';
import SubscriptionProvider from './SubscriptionProvider';
import { SubscriptionVersions } from './SubscriptionVersions';
import { useEntity } from '@backstage/plugin-catalog-react';

const SubscriptionDrilldown = ({ name }: { name: string | undefined }) => {
  return (
    <Page themeId="tool">
      <Header
        title={name}
        type="Subscription"
        typeLink="/qm-ephemeral-subscriptions"
      >
        <HeaderLabel label="Owner" value="platform-delivery" />
        <HeaderLabel label="Lifecycle" value="Alpha" />
      </Header>
      <SubscriptionProvider name={name as string}>
        <TabbedLayout>
          <TabbedLayout.Route path="/" title="Overview">
            <SubscriptionDetail />
          </TabbedLayout.Route>

          <TabbedLayout.Route path="/config" title="Configuration">
            <SubscriptionConfig />
          </TabbedLayout.Route>
          {/*
          <TabbedLayout.Route path="/access" title="Access Control">
            <SubscriptionAccessControl />
          </TabbedLayout.Route>

          <TabbedLayout.Route path="/flags" title="Feature Flags">
            <SubscriptionFeatureFlags />
          </TabbedLayout.Route>
          */}
          <TabbedLayout.Route path="/versions" title="Versions">
            <SubscriptionVersions />
          </TabbedLayout.Route>
        </TabbedLayout>
      </SubscriptionProvider>
    </Page>
  );
};

export const SubscriptionEntityPage = () => {
  const entity = useEntity();
  return <SubscriptionDrilldown name={entity.entity.metadata.name} />;
};

export const SubscriptionPage = () => {
  const params = useParams();

  const { name } = params;

  return <SubscriptionDrilldown name={name} />;
};

import { createPermission } from '@backstage/plugin-permission-common';

export const SUBSCRIPTION_RESOURCE_TYPE = 'subscription';

export const subscriptionListPermission = createPermission({
  name: 'qm.subscription.list',
  attributes: { action: 'read' },
});

export const subscriptionCreatePermission = createPermission({
  name: 'qm.subscription.create',
  attributes: { action: 'create' },
});

export const subscriptionCreateDataWithSourcePermission = createPermission({
  name: 'qm.subscription.create-with-data-source',
  attributes: { action: 'create' },
});

export const subscriptionUpdatePermission = createPermission({
  name: 'qm.subscription.update',
  attributes: { action: 'update' },
  resourceType: SUBSCRIPTION_RESOURCE_TYPE,
});

export const subscriptionUpdateWithDataSourcePermission = createPermission({
  name: 'qm.subscription.update-with-data-source',
  attributes: { action: 'update' },
  resourceType: SUBSCRIPTION_RESOURCE_TYPE,
});

export const subscriptionUpdatePolicyPermission = createPermission({
  name: 'qm.subscription.update-policy',
  attributes: { action: 'update' },
  resourceType: SUBSCRIPTION_RESOURCE_TYPE,
});

export const subscriptionDeletePermission = createPermission({
  name: 'qm.subscription.delete',
  attributes: { action: 'delete' },
  resourceType: SUBSCRIPTION_RESOURCE_TYPE,
});

export const PERMISSIONS = {
  CREATE: subscriptionCreatePermission,
  CREATE_WITH_DATA_SOURCE: subscriptionCreateDataWithSourcePermission,
  LIST: subscriptionListPermission,
  UPDATE: subscriptionUpdatePermission,
  UPDATE_WITH_DATA_SOURCE: subscriptionUpdateWithDataSourcePermission,
  UPDATE_POLICY: subscriptionUpdatePolicyPermission,
  DELETE: subscriptionDeletePermission,
};

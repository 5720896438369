/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import useAsync from 'react-use/lib/useAsync';

import {
  Entity,
  CompoundEntityRef,
  DEFAULT_NAMESPACE,
  parseEntityRef,
} from '@backstage/catalog-model';
import React from 'react';
import { entityRouteRef } from '@backstage/plugin-catalog-react';
import { Link, LinkProps } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import { Tooltip } from '@material-ui/core';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { Progress, ResponseErrorPanel } from '@backstage/core-components';

/**
 * Props for {@link EntityRefLink}.
 *
 * @public
 */
export type EntityRefLinkProps = {
  entityRef: Entity | CompoundEntityRef | string;
  defaultKind?: string;
  title?: string;
  children?: React.ReactNode;
} & Omit<LinkProps, 'to'>;

type NamedEntity = {
  metadata: { name: string };
  spec: { profile: { displayName: string } };
};

/**
 * Shows a clickable link to an entity.
 *
 * @public
 */
export const EntityLinkComponent = (props: EntityRefLinkProps) => {
  const catalogApi = useApi(catalogApiRef);
  const { entityRef, defaultKind, title, children, ...linkProps } = props;
  const entityRoute = useRouteRef(entityRouteRef);

  let kind;
  let namespace;
  let name;

  if (typeof entityRef === 'string') {
    const parsed = parseEntityRef(entityRef);
    kind = parsed.kind;
    namespace = parsed.namespace;
    name = parsed.name;
  } else if ('metadata' in entityRef) {
    kind = entityRef.kind;
    namespace = entityRef.metadata.namespace;
    name = entityRef.metadata.name;
  } else {
    kind = entityRef.kind;
    namespace = entityRef.namespace;
    name = entityRef.name;
  }

  kind = kind.toLocaleLowerCase('en-US');
  namespace = namespace?.toLocaleLowerCase('en-US') ?? DEFAULT_NAMESPACE;

  const params = { kind, namespace, name };

  const {
    loading,
    error,
    value: e,
  } = useAsync(async () => {
    const entityByRef = await catalogApi.getEntityByRef(params);

    return entityByRef as Entity | NamedEntity | undefined;
  }, [catalogApi]);

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  } else if (!e) {
    return <ResponseErrorPanel error={Error('No entity found')} />;
  }

  let displayName = e.metadata.name;

  if ((e as NamedEntity).spec?.profile?.displayName) {
    displayName = (e as NamedEntity).spec.profile.displayName;
  }

  const link = (
    <Link {...linkProps} to={entityRoute(params)}>
      {children}
      {!children && (title ?? displayName)}
    </Link>
  );

  return title ? <Tooltip title={displayName}>{link}</Tooltip> : link;
};

export type EntityRefLinksProps = {
  entityRefs: (Entity | CompoundEntityRef | string)[];
  defaultKind?: string;
  title?: string;
} & Omit<LinkProps, 'to'>;

export const EntityLinkListComponent = (props: EntityRefLinksProps) => {
  const { defaultKind, title } = props;
  const links = props.entityRefs.map((ref, idx) => (
    <>
      {idx > 0 ? <span>, </span> : ''}
      <EntityLinkComponent
        defaultKind={defaultKind}
        title={title}
        entityRef={ref}
      />
    </>
  ));
  return <>{links}</>;
};

import React from 'react';
import { Chip } from '@material-ui/core';

export const DataSource = ({
  label,
  type,
}: {
  label: string;
  type: string;
}) => {
  if (type !== 'ephemeral') {
    // Permanent subs are their own datasource
    return <></>;
  }
  return <Chip variant="outlined" label={label} />;
};

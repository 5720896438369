import React from 'react';
import { LargeTooltip } from './LargeTooltip';

/**
 *
 * @param timestamp unix timestamp for some point in the future
 * @returns {string} human readable string
 */
const toFutureRelativeDate = (
  timestamp: number | undefined,
  now = Date.now(),
): string => {
  const ts = timestamp;
  if (ts === undefined) {
    return 'Never';
  }

  if (ts < now) {
    return 'Past';
  }

  const diffSeconds = Math.floor(Math.abs(now - ts) / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);
  const diffWeeks = Math.floor(diffDays / 7);
  const diffMonths = Math.floor(diffWeeks / 4); // 4 weeks per month, not entirely accurate but okay for human-readable.

  const plural = (unit: number) => (unit === 1 ? '' : 's');

  if (diffMonths > 0) {
    return `${diffMonths} month${plural(diffMonths)}`;
  }

  if (diffWeeks > 0) {
    return `${diffWeeks} week${plural(diffWeeks)}`;
  }

  if (diffDays > 0) {
    const andHours = diffHours % 24;
    return `${diffDays} day${plural(diffDays)}, ${andHours} hour${plural(
      andHours,
    )}`;
  }

  if (diffHours > 0) {
    const andMinutes = diffMinutes % 60;
    return `${diffHours} hour${plural(diffHours)}, ${andMinutes} minute${plural(
      andMinutes,
    )}`;
  }

  if (diffMinutes > 0) {
    return `${diffMinutes} minute${plural(diffMinutes)}`;
  }

  return 'Less than one minute';
};

const toRelativeDate = (timestamp: number | undefined) => {
  const ts = timestamp;
  if (ts === undefined) {
    return 'Never';
  }

  const now = Date.now();

  if (ts > now) {
    return `In ${toFutureRelativeDate(timestamp, now)}`;
  }
  return `${toFutureRelativeDate(now, timestamp)} ago`;
};

export const NiceDate = ({ date }: { date: string }) => {
  const dateObj = new Date(date);

  const relativeDate = toRelativeDate(dateObj.getTime());
  const absoluteDate = dateObj.toLocaleString();

  return (
    <LargeTooltip title={absoluteDate}>
      <span>{relativeDate}</span>
    </LargeTooltip>
  );
};

export const NiceFutureDate = ({ date }: { date: string }) => {
  const dateObj = new Date(date);

  const relativeDate = toFutureRelativeDate(dateObj.getTime());
  const absoluteDate = dateObj.toLocaleString();

  return (
    <LargeTooltip title={absoluteDate}>
      <span>{relativeDate}</span>
    </LargeTooltip>
  );
};

export const Expiration = ({ date, type }: { date: string; type: string }) => {
  if (type !== 'ephemeral') {
    // Permanent subs do not expire
    return <></>;
  }
  return <NiceFutureDate date={date} />;
};

import { Progress } from '@backstage/core-components';
import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import InfoIcon from '@material-ui/icons/Info';

type Policy = {
  name: string;
  id: string;
  value: boolean;
  description: string;
  newValue?: boolean;
};

interface SubscriptionPoliciesProps {
  dialogOpen: boolean;
  handleCloseDialog: () => void;
  selectedRow: {
    sub: string;
    type: string;
  };
}

const usePolicyStyles = makeStyles({
  tooltip: {
    marginLeft: '4px',
    verticalAlign: 'text-bottom',
  },
  text: {
    fontSize: '12pt',
  },
});

const PolicyNameAndDescription = (props: {
  name: string;
  description: string;
}) => {
  const classes = usePolicyStyles();
  const { name, description } = props;
  return (
    <>
      {name}
      <Tooltip
        title={description}
        className={classes.tooltip}
        classes={{ tooltip: classes.text }}
        placement="right"
      >
        <InfoIcon fontSize="small" />
      </Tooltip>
    </>
  );
};

export const SubscriptionPoliciesDialog = ({
  dialogOpen,
  handleCloseDialog,
  selectedRow,
}: SubscriptionPoliciesProps) => {
  const [policies, setPolicies] = useState<Policy[]>([{} as Policy]);
  const [confirm, setConfirm] = useState('');
  const [tenantId, setTenantId] = useState('');

  const discoveryApi = useApi(discoveryApiRef);
  const { fetch } = useApi(fetchApiRef);
  const env = selectedRow.type === 'ephemeral' ? 'development' : 'production';
  const policiesPath = encodeURI('/api/v1/policies');
  const tenantsPath = encodeURI('/api/v1/tenants/');

  const allowSubmit = confirm === selectedRow.sub;

  const { loading } = useAsync(async () => {
    if (!dialogOpen) return;

    // Get all policies
    const iamPoliciesUrl = `${await discoveryApi.getBaseUrl(
      'qm-iam-proxy',
    )}/iam?env=${env}&path=${policiesPath}`;
    const response = await fetch(iamPoliciesUrl);
    const data = await response.json();

    // Check tenants to see if they have the above policies to determine init value
    const tenantLookupUrl = `${await discoveryApi.getBaseUrl(
      'qm-iam-proxy',
    )}/iam?env=${env}&path=${tenantsPath}&query={"subdomain": "${
      selectedRow.sub
    }"}`;
    const tenantData = await (await fetch(tenantLookupUrl)).json();
    const foundTenantId = tenantData.find(
      (tenant: { subdomain: string; id: string }) =>
        tenant.subdomain === selectedRow.sub,
    )?.id;
    if (!foundTenantId) {
      setPolicies([]);
      return;
    }
    setTenantId(foundTenantId);
    const tenantsPoliciesPath = `/api/v1/tenants/${foundTenantId}/policies`;
    const policiesTenantUrl = `${await discoveryApi.getBaseUrl(
      'qm-iam-proxy',
    )}/iam?env=${env}&path=${tenantsPoliciesPath}`;
    const tenantPolicies = await (await fetch(policiesTenantUrl)).json();
    setPolicies(
      data.map((policy: { name: string; description: string; id: string }) => {
        const initValue = tenantPolicies.find(
          (tenantPolicy: { tenant_id: string; policy_id: string }) =>
            tenantPolicy.policy_id === policy.id,
        );
        return {
          name: policy.name,
          id: policy.id,
          description: policy.description,
          value: Boolean(initValue),
        };
      }),
    );
  }, [fetch, setPolicies, setTenantId, discoveryApi, dialogOpen]);

  const close = () => {
    handleCloseDialog();
    setTimeout(() => setConfirm(''), 100);
  };

  const handleSubmit = () => {
    policies.forEach(policy => {
      const tenantsPoliciesPath = `/api/v1/policies/${policy.id}/tenant/${tenantId}`;

      discoveryApi.getBaseUrl('qm-iam-proxy').then(url => {
        const policiesTenantUrl = `${url}/iam?env=${env}&path=${tenantsPoliciesPath}`;
        if (policy.newValue === undefined) return;

        fetch(policiesTenantUrl, {
          method: policy.newValue ? 'POST' : 'DELETE',
        });
      });
    });
    close();
  };

  return (
    <Dialog open={dialogOpen} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle>Update Policies</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {loading ? (
            <Progress />
          ) : (
            <>
              <Grid item xs={12} style={{ marginBottom: '2rem' }}>
                <Typography>
                  Update subscription policies for{' '}
                  <strong>{selectedRow?.sub}</strong>.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <form style={{ marginBottom: '1rem' }}>
                  <Grid item xs={12}>
                    <FormGroup>
                      {policies.length === 0
                        ? 'No policies found for this IAM instance.'
                        : policies.map(policy => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={
                                    policy.newValue || policy.value
                                  }
                                  id={policy.name}
                                  onChange={e => {
                                    const oldPolicies = policies;
                                    oldPolicies.map(p => {
                                      if (p.name === e.target.id) {
                                        p.newValue = !p.value;
                                      }
                                    });
                                    setPolicies(oldPolicies);
                                  }}
                                />
                              }
                              label={
                                <PolicyNameAndDescription
                                  name={policy.name}
                                  description={policy.description}
                                />
                              }
                              key={policy.name}
                            />
                          ))}
                    </FormGroup>
                  </Grid>
                </form>
                {policies.length > 0 && (
                  <Grid item xs={12}>
                    <TextField
                      key="confirm"
                      fullWidth
                      variant="outlined"
                      style={{ marginTop: '10px' }}
                      label="Enter the subscription name to confirm"
                      value={confirm}
                      onChange={e => {
                        setConfirm(e.target.value);
                      }}
                      helperText={`${selectedRow.sub}`}
                    />
                  </Grid>
                )}
              </Grid>
              <DialogActions>
                <Button onClick={close} color="default">
                  Close
                </Button>
                {policies.length > 0 && (
                  <Button
                    onClick={handleSubmit}
                    disabled={!allowSubmit}
                    color="primary"
                  >
                    Submit
                  </Button>
                )}
              </DialogActions>
            </>
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionPoliciesDialog;

import React from 'react';
import {
  StatusError,
  StatusOK,
  StatusPending,
  StatusRunning,
  StatusWarning,
} from '@backstage/core-components';

export const Status = ({
  status,
  type,
  operations,
}: {
  status: string;
  type: string;
  operations: number;
}) => {
  if (type !== 'ephemeral') {
    // Status for permanent subs is a bit misleading. Blanking for now
    return <></>;
  }

  return (
    <div>
      {(!status && (
        <div>
          <StatusOK /> Permanent
        </div>
      )) ||
        (status === 'pending_deletion' && (
          <div>
            <StatusWarning /> Deleting
          </div>
        )) ||
        (status === 'pending_creation' && (
          <div>
            <StatusPending /> Creating
          </div>
        )) ||
        (status === 'marked_for_deletion' && (
          <div>
            <StatusPending /> Deleting
          </div>
        )) ||
        status === 'updating' ||
        (operations > 0 && (
          <div>
            <StatusRunning /> Updating
          </div>
        )) ||
        (status === 'invalid' && (
          <div>
            <StatusError /> Invalid
          </div>
        )) ||
        (status === 'ready' && (
          <div>
            <StatusOK /> OK
          </div>
        ))}
    </div>
  );
};

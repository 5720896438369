import React from 'react';
import { Tooltip, makeStyles } from '@material-ui/core';

const usePolicyStyles = makeStyles({
  tooltip: {
    marginLeft: '4px',
    verticalAlign: 'text-bottom',
  },
  text: {
    fontSize: '10pt',
  },
});

export const LargeTooltip = ({
  children,
  title,
}: {
  children: React.ReactElement<any, any>;
  title: NonNullable<React.ReactNode>;
}) => {
  const classes = usePolicyStyles();
  return (
    <Tooltip
      className={classes.tooltip}
      classes={{ tooltip: classes.text }}
      title={title}
    >
      {children}
    </Tooltip>
  );
};

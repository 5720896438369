import { Chip, Tooltip } from '@material-ui/core';
import React from 'react';

export const DockerImage = ({ image }: { image: string }) => {
  let tag = image.split(':')[1];

  if (!tag) {
    tag = image;
  }

  return (
    <Tooltip title={image}>
      <Chip variant="outlined" label={tag} />
    </Tooltip>
  );
};

import {
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import type { Service } from '../../types';

type tagServiceDialogProps = {
  dialogOpen: boolean;
  service: Service;
  handleDialogClose: () => void;
  subName: string;
};

export const TagServiceDialog = (props: tagServiceDialogProps) => {
  const {
    service: { image },
  } = props;
  const { fetch } = useApi(fetchApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const identityApi = useApi(identityApiRef);

  const [confirmText, setConfirmText] = useState<string | undefined>(undefined);
  const [tag, setTag] = useState<string>('');
  const [tagNote, setTagNote] = useState<string | undefined>(undefined);

  useEffect(() => {
    // image from the props is initially empty when this component first loads, so set tag when we get a value
    setTag(image);
  }, [image]);

  const validInput = (): boolean => {
    if (
      confirmText === undefined ||
      confirmText.length === 0 ||
      confirmText !== props.subName
    ) {
      return false;
    }

    if (!tagNote) {
      return false;
    }

    return true;
  };
  const valid = validInput();

  const handleClose = () => {
    props.handleDialogClose();
  };
  const handleConfirmChange = ({
    target: { value },
  }: {
    target: { value: string };
  }) => {
    setConfirmText(value);
  };

  const handleNoteChange = (noteValue: string) => {
    setTagNote(noteValue);
  };
  const handleTagChange = (tagValue: string) => {
    if (tagValue) {
      setTag(tagValue);
    }
  };
  const handleSubmit = () => {
    (async () => {
      const currentUser = await identityApi.getProfileInfo();
      const body = JSON.stringify({
        version: tag,
        note: tagNote,
        user: currentUser?.email,
      });
      const subscriptionManagerUrl = `${await discoveryApi.getBaseUrl(
        'proxy',
      )}/subscription-manager`;
      await fetch(
        `${subscriptionManagerUrl}/api/v1/subscriptions/${props.subName}/service/${props.service.name}`,
        { method: 'PUT', body },
      );
      props.handleDialogClose();
    })();
  };

  return (
    <Dialog
      open={props?.dialogOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Tag Service</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid item xs={12} style={{ marginBottom: '10px' }}>
            <Typography>
              Change service tag for <strong>{props?.service?.name}</strong> on
              sub <strong>{props?.subName}</strong>.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Tag"
                variant="outlined"
                onChange={e => {
                  handleTagChange(e.target.value);
                }}
                defaultValue={props.service.image}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Reason for Tag"
                variant="outlined"
                onChange={e => {
                  handleNoteChange(e.target.value);
                }}
                defaultValue=""
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                variant="outlined"
                label="Enter the subscription name to confirm"
                onChange={handleConfirmChange}
                helperText={props.subName}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose} color="default">
              Close
            </Button>
            <Button disabled={!valid} onClick={handleSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

import { ErrorPage } from '@backstage/core-components';
import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import React, { createContext, useState } from 'react';
import { useAsync } from 'react-use';
import type { Service, Subscription } from '../../types';

type SubscriptionState = Subscription & {
  services?: Service[];
  code?: number;
  env: string;
  tenantId: string;
};

type SubProvider = SubscriptionState & {
  refresh: () => void;
};

export const SubscriptionContext = createContext<SubProvider>(
  {} as SubProvider,
);

type ProviderProps = {
  children: any;
  name: string;
};

const SubscriptionProvider = ({ children, name }: ProviderProps) => {
  const [subscription, setSubscription] = useState<SubscriptionState>(
    {} as SubscriptionState,
  );

  const [refresher, setRefresher] = useState<number>(0);

  const refresh = () => setRefresher(r => r + 1);

  const { fetch } = useApi(fetchApiRef);
  const discoveryApi = useApi(discoveryApiRef);

  const tenantsPath = encodeURI('/api/v1/tenants/');

  const { loading } = useAsync(async (): Promise<Subscription[]> => {
    const subscriptionManagerUrl = `${await discoveryApi.getBaseUrl(
      'proxy',
    )}/subscription-manager`;
    const response = await fetch(
      `${subscriptionManagerUrl}/api/v1/subscriptions/all/${name}`,
    );
    const data = await response.json();

    data.env = data.type === 'ephemeral' ? 'development' : 'production';

    const tenantLookupUrl = `${await discoveryApi.getBaseUrl(
      'qm-iam-proxy',
    )}/iam?env=${data.env}&path=${tenantsPath}&query={"subdomain": "${
      data.name
    }"}`;
    const tenantData = await (await fetch(tenantLookupUrl)).json();
    data.tenantId = tenantData.find(
      (tenant: { subdomain: string; id: string }) =>
        tenant.subdomain === data.name,
    )?.id;
    setSubscription(data);
    return data;
  }, [name, refresher]);

  if (subscription.code === 404) {
    return (
      <ErrorPage status="404" statusMessage="Subscription can not be found." />
    );
  }

  if (loading) {
    return null;
  }

  return (
    <SubscriptionContext.Provider value={{ ...subscription, refresh }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
export default SubscriptionProvider;

import { TableColumn } from '@backstage/core-components/index';
import {
  CatalogTable,
  CatalogTableColumnsFunc,
  CatalogTableRow,
} from '@backstage/plugin-catalog';

const ownerColumn: TableColumn<CatalogTableRow> = {
  title: 'Owner',
  field: 'entity.spec.owner',
  width: 'auto',
};

const statusColumn: TableColumn<CatalogTableRow> = {
  title: 'Status',
  field: 'entity.spec.status',
  width: 'auto',
};

const typeColumn: TableColumn<CatalogTableRow> = {
  title: 'Type',
  field: 'entity.spec.type',
  width: 'auto',
};

const hostColumn: TableColumn<CatalogTableRow> = {
  title: 'Host',
  field: 'entity.spec.host',
  width: 'auto',
};

export const subscriptionColumnsFunc: CatalogTableColumnsFunc =
  entityListContext => {
    if (
      entityListContext.filters.kind?.value?.toLowerCase() === 'subscription'
    ) {
      return [
        CatalogTable.columns.createNameColumn(),
        ownerColumn,
        statusColumn,
        typeColumn,
        hostColumn,
      ];
    }
    return CatalogTable.defaultColumnsFunc(entityListContext);
  };

import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { createContext, useState } from 'react';

type UpdateSubscriptionContext = {
  showDialog: (subscription: string, currentDataSource?: string) => void;
  hideDialog: () => void;
};

const initialState = {
  showDialog: () => {},
  hideDialog: () => {},
};

export const UpdateSubscriptionDataSourceContext =
  createContext<UpdateSubscriptionContext>(initialState);

type ComponentProps = {
  subscription: string | null;
  currentDataSource?: string;
};

export const UpdateSubscriptionDataSourceComponent = ({
  subscription,
  currentDataSource,
  handleCloseDialog,
}: ComponentProps & { handleCloseDialog: () => void }) => {
  const { fetch } = useApi(fetchApiRef);
  const discoveryApi = useApi(discoveryApiRef);

  const [submitting, setSubmitting] = useState<boolean>();
  const [dataSource, setDataSource] = useState<string | undefined>(undefined);
  const [confirmText, setConfirmText] = useState<string | undefined>(undefined);

  const close = () => {
    setDataSource(undefined);
    setConfirmText(undefined);
    handleCloseDialog();
  };

  const handleSubmit = () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    (async () => {
      const subscriptionManagerUrl = `${await discoveryApi.getBaseUrl(
        'proxy',
      )}/subscription-manager`;
      await fetch(
        `${subscriptionManagerUrl}/api/v1/subscriptions/${subscription}`,
        { method: 'PUT', body: JSON.stringify({ data_source: dataSource }) },
      );
      handleCloseDialog();
      setSubmitting(false);
    })();
  };

  const validInput = (): [boolean, string] => {
    if (dataSource === undefined || dataSource.length === 0) {
      // do not error when the form is first opened, but do not allow submit either
      if (confirmText === subscription) {
        // however, if they edited the form elsewhere, give them the error.
        return [false, 'You must change the data source.'];
      }
      return [false, ''];
    }

    if (dataSource === currentDataSource) {
      return [false, 'You must change the data source.'];
    }

    if (
      confirmText === undefined ||
      confirmText.length === 0 ||
      confirmText !== subscription
    ) {
      return [false, ''];
    }

    return [true, ''];
  };

  const [valid, invalidReason] = validInput();

  const handleChange = ({
    target: { value },
  }: {
    target: { value: string };
  }) => {
    setDataSource(value);
  };

  const handleConfirmChange = ({
    target: { value },
  }: {
    target: { value: string };
  }) => {
    setConfirmText(value);
  };

  return (
    <Dialog open={!!subscription} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle>Update Subscription Data Source</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid item xs={12} style={{ marginBottom: '10px' }}>
            <Typography>
              Edit the data source for the subscription{' '}
              <strong>{subscription}</strong>.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Data Source"
                variant="outlined"
                defaultValue={currentDataSource}
                onChange={handleChange}
                error={invalidReason !== ''}
                helperText={invalidReason}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                variant="outlined"
                label="Enter the subscription name to confirm"
                onChange={handleConfirmChange}
                helperText={subscription}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={close} color="default">
              Close
            </Button>
            <Button disabled={!valid} onClick={handleSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
